import { AnyAction } from 'redux'
import { createAction, ActionType } from 'typesafe-actions'

import { batchActions } from 'src/core/batchMiddleware'

import i18n from 'src/i18n'
import {
  instanceDelete,
  documentErrorsUpdate,
  revisionSaved,
  instanceEdit,
} from 'src/service-design/shared/document/actions'
import { InstanceRepair } from 'src/service-design/shared/document/repairs'
import { getSaveSelector } from 'src/service-design/shared/document/selectors/save'
import { DocumentSpec } from 'src/service-design/shared/document/types'
import { validateAll } from 'src/service-design/shared/document/validation'
import { getCollectionData } from 'src/service-design/shared/document-factory/factory-input'
import {
  ackableErrorModalShow,
  blockingErrorModalShow,
} from 'src/service-design/shared/modals/actions'

import { BrokenRelationship } from './objects'
import { findBrokenRelations } from './relationships'

export const documentLoaded = createAction('DOCUMENT_LOADED')()
export const documentHasErrors = createAction('DOCUMENT_HAS_ERRORS')()
export const acknowledgeDocumentErrors = createAction('DOCUMENT_CLEAR_ERRORS')()

export const documentLoadActions = {
  documentLoaded,
  documentHasErrors,
  acknowledgeDocumentErrors,
} as const
export type DocumentLoadActionType = ActionType<typeof documentLoadActions>

export const resolveErrors = (
  problems: (BrokenRelationship | InstanceRepair)[],
) => {
  let brokenResultObj: {
    [errorId: string]: {
      sourceMissingName: string
      sourceMissingId: string
      collections: { [collectionName: string]: Set<string> }
    }
  } = {}
  const brokenRels: BrokenRelationship[] = problems.filter(
    (p): p is BrokenRelationship => p instanceof BrokenRelationship,
  )
  for (const problem of brokenRels) {
    const objNameDelete = problem.collectionName
    const objIdDelete = problem.affectedObj.id
    const sourceMissingName = problem.relationDefinition.collection
    const sourceMissingId =
      // @ts-ignore type-safe is not a thing in this file
      problem.affectedObj[problem.relationDefinition.foreign]

    const topLevelKey = `${sourceMissingName}:${sourceMissingId}`

    if (!brokenResultObj[topLevelKey]) {
      brokenResultObj[topLevelKey] = {
        sourceMissingName,
        sourceMissingId,
        collections: {},
      }
    }

    if (!brokenResultObj[topLevelKey].collections[objNameDelete]) {
      brokenResultObj[topLevelKey].collections[objNameDelete] = new Set()
    }

    brokenResultObj[topLevelKey].collections[objNameDelete].add(objIdDelete)
  }

  // Basically convert the Set's to plain objects
  brokenResultObj = Object.assign(
    {},
    ...Object.entries(brokenResultObj).map(
      ([topKey, { collections, ...rest }]) => ({
        [topKey]: Object.assign(
          rest,
          ...Object.entries(collections).map(([collectionName, ids]) => ({
            collections: { [collectionName]: [...ids] },
          })),
        ),
      }),
    ),
  )

  const repairsResultObj: {
    [errorId: string]: { message: string }
  } = {}
  const repairs = problems.filter(
    (p): p is InstanceRepair => p instanceof InstanceRepair,
  )
  for (const { message, context, derived } of repairs) {
    const objErrorMsg = i18n.t(message, { entity: context, ...derived })
    repairsResultObj[objErrorMsg] = { message: objErrorMsg }
  }
  return { ...repairsResultObj, ...brokenResultObj }
}

export const documentAckErrorsUpdate = (problems: any) => (dispatch: any) => {
  const resultObj = resolveErrors(problems)

  dispatch(ackableErrorModalShow(resultObj))
  dispatch(documentHasErrors())
}

// We loop here until all problems are fixed as fixing one may reveal another

const applyUntilFixed =
  (
    func: (
      dispatch: any,
      getState: any,
    ) => Promise<{ fixed?: any[]; unfixable?: any[] }>,
  ) =>
  async (dispatch: any) => {
    const fixedProblems = []
    // FIXME: Bulk ignored when upgrading react-scripts
    // eslint-disable-next-line no-constant-condition, no-unreachable-loop
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const { fixed = [] } = await dispatch(func)
      fixedProblems.push(...fixed)
      if (fixed.length !== 0) {
        return { fixed: fixedProblems }
      }
      return {}
    }
  }

export const repairInstanceIntegrityRules =
  ({ repairs }: DocumentSpec<any>) =>
  async (dispatch: any, getState: any) => {
    const fixed = repairs ? Object.values(repairs.build(getState())).flat() : []
    if (fixed.length > 0) {
      await dispatch(
        batchActions(
          // @ts-ignore we're far from being type-safe people...
          fixed.map(({ collectionName, affectedObj }) =>
            // @ts-ignore Can't use instance delete in a type safe way from here
            // as yet
            instanceDelete(collectionName, affectedObj.id),
          ),
        ),
      )
    }

    return { fixed }
  }

export const fixBrokenRelations =
  (brokenRelationships: BrokenRelationship[]) => (dispatch: any) => {
    const batchedActions: AnyAction[] = []
    const deletedObjIds: string[] = []
    for (const relationship of brokenRelationships) {
      if (relationship.relationDefinition.allowNull) {
        const obj = relationship.affectedObj
        // @ts-ignore post-document-load... not close to type safe
        obj[relationship.relationDefinition.name] = null
        // @ts-ignore Can't use instance edit in a type safe way from here
        // as yet
        // ASIDE: do we even use this functionality any more? I think not
        batchedActions.push(instanceEdit(relationship.collectionName, obj))
      } else {
        if (!deletedObjIds.includes(relationship.affectedObj.id)) {
          batchedActions.push(
            // @ts-ignore Can't use instance delete in a type safe way from here
            // as yet
            instanceDelete(
              relationship.collectionName,
              relationship.affectedObj.id,
            ),
          )
        }
        deletedObjIds.push(relationship.affectedObj.id)
      }
    }
    return dispatch(batchActions(batchedActions))
  }

export const cascadeParentDeletes =
  ({ relationships }: DocumentSpec<any>) =>
  (dispatch: any, getState: any) => {
    const problems = findBrokenRelations(
      relationships,
      // @ts-ignore due to the way the document reducer is initialized, calls
      // to getCollectionData aren't guaranteed to be returned but in practise they
      // are
      getCollectionData(getState()),
    )

    if (problems.length > 0) {
      dispatch(fixBrokenRelations(problems))
    }

    return { fixed: problems }
  }

export const validateDocumentSchema =
  (documentSpec: DocumentSpec<any>) => (dispatch: any, getState: any) => {
    const { documents } = getState()
    const errors = validateAll(Object.values(documents), documentSpec)
    return { unfixable: errors }
  }

export const postDocumentLoad =
  ({ documentSpec }: { documentSpec: DocumentSpec<any> }) =>
  async (dispatch: any) => {
    const fixedProblems = []

    try {
      for (const phase of [
        validateDocumentSchema(documentSpec),
        cascadeParentDeletes(documentSpec),
        applyUntilFixed(repairInstanceIntegrityRules(documentSpec)),
      ]) {
        // eslint-disable-next-line no-await-in-loop
        const { unfixable = [], fixed = [] } = await dispatch(phase)

        if (unfixable.length > 0) {
          return { unfixable }
        }

        fixedProblems.push(...fixed)
      }

      await dispatch(documentLoaded())
      return { fixed: fixedProblems }
    } catch (e) {
      console.error(e)
      return {
        unfixable: [
          {
            id: 'unexpected-error',
            message:
              'Sorry, there was an unexpected error. It has been reported.',
          },
        ],
      }
    }
  }

export const postDocumentLoadDisplayModalError =
  ({
    documentSpec,
    enableSave,
  }: {
    documentSpec: DocumentSpec<any>
    enableSave: boolean
  }) =>
  async (dispatch: any, getState: any) => {
    if (enableSave) {
      const initialData = getSaveSelector()(getState()).data
      dispatch(revisionSaved(initialData))
    }

    const { unfixable = [], fixed = [] } = await dispatch(
      postDocumentLoad({ documentSpec }),
    )

    if (unfixable.length > 0) {
      // eslint-disable-next-line no-await-in-loop
      await dispatch(documentErrorsUpdate(unfixable))
      await dispatch(blockingErrorModalShow(unfixable))
      return { unfixable }
    }
    if (fixed.length > 0) {
      dispatch(documentAckErrorsUpdate(fixed))
      return { fixed }
    }
    return {}
  }

export const postDocumentLoadCheckOnly =
  ({ documentSpec }: { documentSpec: DocumentSpec<any> }) =>
  async (dispatch: any) => {
    const { unfixable = [], fixed = [] } = await dispatch(
      postDocumentLoad({ documentSpec }),
    )

    return { unfixable, fixed: resolveErrors(fixed) }
  }
