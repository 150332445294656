import { ValidateFunction } from 'src/validateSchema'

export class SchemaError {
  static type = 'Schema Error'

  constructor(
    public id: any,
    public message: any,
  ) {}

  get type() {
    return (this.constructor as any).type
  }
}

export const validateSchema = <T>(data: any, validate: ValidateFunction<T>) => {
  if (validate(data)) {
    return []
  }

  return validate.errors.map(
    error =>
      new SchemaError(
        error.instancePath,
        [validate.schema?.$id, error.instancePath, error.message]
          .filter(Boolean)
          .join(' '),
      ),
  )
}
