import qs from 'qs'

import { getAuthToken } from 'src/core/auth'

import {
  SAVE_CONNECTION_ERROR_STATUS,
  SAVE_CONNECTION_ERROR,
} from './constants'

type Config = {
  headers?: {
    Accept: string
    'Content-Type': string
    Authorization?: string
  }
  baseUrl: string
}

export const DEFAULT_CONFIG: Config = {
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  },
  baseUrl: '/document/api',
}

type Args = {
  url: string
  json?: object
  config?: Config
  method?: string
  query?: object
}
type FetchResult = {
  status: number
  statusText: string
  ok: boolean
  data: { message?: string; code?: string; status?: string }
}

const isFetchError = (e: unknown): e is FetchResult =>
  'statusText' in (e as FetchResult)

const handleFetch = async ({
  url,
  json = undefined,
  config = DEFAULT_CONFIG,
  method = 'GET',
  query,
}: Args) => {
  const body = json !== undefined ? { body: JSON.stringify(json) } : {}
  const queryStr =
    Object.keys(query).length > 0 ? `?${qs.stringify(query)}` : ''

  const { baseUrl, headers } = {
    ...DEFAULT_CONFIG,
    ...config,
  }
  let response
  let data

  const actualHeaders = { ...headers }

  const token = await getAuthToken()
  if (token) {
    actualHeaders.Authorization = `Bearer ${token}`
  }

  try {
    // window.fetch so it may be mocked in cypress  (https://github.com/cypress-io/cypress/issues/95)
    response = await window.fetch(`${baseUrl}${url}${queryStr}`, {
      ...body,
      headers: actualHeaders,
      method,
    })

    try {
      data = await response.json()
    } catch (e) {
      data = {
        message: 'Unknown error',
      }
    }
  } catch (e) {
    response = {
      status: SAVE_CONNECTION_ERROR_STATUS,
    }
    data = {
      message: `Please check your connection ${SAVE_CONNECTION_ERROR}`,
    }
  }

  const result = {
    status: response.status,
    statusText: response.statusText,
    ok: response.ok,
    data,
  }

  if (!response.ok) {
    if (response.status >= 500) {
      console.error('fetch failed: ', result)
    }
    throw result
  }
  return result
}

const get = ({
  url,
  config = DEFAULT_CONFIG,
  query = {},
}: {
  url: string
  config?: Config
  query?: {}
}) =>
  handleFetch({
    url,
    config,
    query,
  })

const post = async ({
  url,
  json,
  config = DEFAULT_CONFIG,
  query = {},
}: {
  url: string
  json: {}
  config?: Config
  query?: {}
}) =>
  handleFetch({
    url,
    json,
    config,
    method: 'POST',
    query,
  })

const put = async ({
  url,
  json,
  config = DEFAULT_CONFIG,
  query = {},
}: {
  url: string
  json: {}
  config?: Config
  query?: {}
}) =>
  handleFetch({
    url,
    json,
    config,
    method: 'PUT',
    query,
  })

const del = async ({
  url,
  config = DEFAULT_CONFIG,
  query = {},
}: {
  url: string
  config?: Config
  query?: {}
}) =>
  handleFetch({
    url,
    config,
    method: 'DELETE',
    query,
  })

export default {
  get,
  post,
  put,
  del,
  isFetchError,
}
