/**
 * See documentation for usage of this context menu system at
 * https://storybook.navisrail.com/?path=/docs/context-menu--page or
 * src/stories/ContextMenu.stories.mdx
 */
import React from 'react'
import styled from 'styled-components'

import { lightTheme } from 'src/core/ui/themes'

type IMenuRef = React.Ref<HTMLElement | undefined>
type ICloseMenu = () => void

type ICurrentMenu = {
  close: ICloseMenu
  ref: IMenuRef
}

type IMenuOpened = (menuRef: IMenuRef, close: ICloseMenu) => void

export const MenuManagerContext = React.createContext<{
  menuOpened: IMenuOpened
  clear: () => void
} | null>(null)

export const MenuManagerProvider: React.FC = ({ children }) => {
  const [currentMenu, setCurrentMenu] = React.useState<ICurrentMenu | null>(
    null,
  )

  const menuOpened = React.useCallback<IMenuOpened>(
    (ref, close) => {
      // we may be opening the same component with a new id and event, if so
      // don't close it
      if (currentMenu && currentMenu.close !== close) {
        currentMenu.close()
      }

      setCurrentMenu({ close, ref })
    },
    [currentMenu],
  )

  const clearCurrentMenu = React.useCallback(() => setCurrentMenu(null), [])

  // register click handler to close opened menu on any unfocusing click
  React.useEffect(() => {
    const onClick = (e: MouseEvent) => {
      const contextMenuClicked = Boolean(
        e.target &&
          e.target instanceof Node &&
          currentMenu?.ref &&
          'current' in currentMenu.ref &&
          currentMenu.ref.current?.contains(e.target),
      )

      if (currentMenu && !contextMenuClicked) {
        currentMenu.close()
        clearCurrentMenu()
      }
    }

    if (currentMenu) {
      document.body.addEventListener('click', onClick, { passive: true })
    }
    return () => document.body.removeEventListener('click', onClick)
  }, [clearCurrentMenu, currentMenu])

  return (
    <MenuManagerContext.Provider
      // FIXME: Bulk ignored when upgrading react-scripts
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ menuOpened, clear: clearCurrentMenu }}
    >
      {children}
    </MenuManagerContext.Provider>
  )
}

const useMenuManager = () => {
  const context = React.useContext(MenuManagerContext)

  if (!context) {
    throw new Error('MenuManagerContext not provided')
  }

  // Unset current menu when component that uses menuManager unmounts, to ensure
  // `close()` is not called on unmounted components
  React.useEffect(() => context.clear, [context.clear])
  return context.menuOpened
}

export type IOpenMenu<Id> = (id: Id, e: React.MouseEvent) => void

type IContext<Id> = React.Context<IOpenMenu<Id> | null>

export type ContextMenuProps<Id> = {
  menuInfo: { id: Id; event: React.MouseEvent }
  closeMenu: ICloseMenu
}

export const createUseMenu =
  <Id extends string>(Context: IContext<Id>) =>
  () => {
    const context = React.useContext(Context)
    if (!context) {
      throw Error('Menu context not provided')
    }

    return context
  }

export const createContextMenuProvider = <Id extends string>(
  Component: React.ComponentType<ContextMenuProps<Id>>,
  Context: IContext<Id>,
): React.FC =>
  // FIXME: Bulk ignored when upgrading react-scripts
  // eslint-disable-next-line func-names
  function ({ children }) {
    const menuOpened = useMenuManager()
    const menuRef = React.useRef<HTMLElement>()

    const [menuInfo, setMenuInfo] = React.useState<{
      id: Id
      event: React.MouseEvent
    } | null>(null)

    const closeMenu = React.useCallback(() => {
      setMenuInfo(null)
    }, [])

    const openMenu = React.useCallback<IOpenMenu<Id>>(
      (id, event) => {
        event.preventDefault()
        event.persist()
        setMenuInfo({ id, event })
        menuOpened(menuRef, closeMenu)
      },
      [closeMenu, menuOpened],
    )

    return (
      <>
        {menuInfo && (
          <ContextMenu
            top={menuInfo.event.pageY}
            left={menuInfo.event.pageX}
            ref={menuRef}
          >
            <Component menuInfo={menuInfo} closeMenu={closeMenu} />
          </ContextMenu>
        )}
        <Context.Provider value={openMenu}>{children}</Context.Provider>
      </>
    )
  }

type ContextMenuComponentProps = {
  top?: number
  left?: number
  ref: IMenuRef
}

export const StyledContextMenu = styled.nav.attrs<ContextMenuComponentProps>(
  ({ top, left }) => ({ style: { top, left } }),
)<ContextMenuComponentProps>`
  position: fixed;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.lightgreyAccent};
  border-radius: 3px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
`

StyledContextMenu.defaultProps = {
  theme: lightTheme,
}

export const MenuSection = styled.section`
  & + & {
    border-top: 1px solid ${({ theme }) => theme.lightgreyAccent};
  }
`

MenuSection.defaultProps = { theme: lightTheme }

export const MenuItem = styled.a`
  display: block;
  padding: 0.5rem 1rem;
  color: ${({ theme }) => theme.mediumgrey};
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.snowwhite};
    color: inherit;
    text-decoration: none;
  }
`

MenuItem.defaultProps = { theme: lightTheme }

export const ContextMenu: React.FC<ContextMenuComponentProps> =
  React.forwardRef(({ top = 0, left = 0, children }, ref) => (
    <StyledContextMenu
      top={top}
      left={left}
      ref={ref as React.MutableRefObject<HTMLElement>}
      className="context-menu"
    >
      {children}
    </StyledContextMenu>
  ))
