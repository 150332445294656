import { getType } from 'is-what'
import { get } from 'lodash'
import { Duration } from 'luxon'

import * as constants from 'src/service-design/core/domain/constants'
import { Delta, CyclicTime } from 'src/service-design/core/domain/dates'

const BASE_DATE_TIME = Date.UTC(2017, 0, 1)

export const toDay = (dayIndex: number) => constants.DAYS[dayIndex]
export const fromDay = (day: (typeof constants.DAYS)[number]) =>
  constants.DAYS.indexOf(day)

export const toTime = (seconds: number) =>
  Duration.fromObject({ seconds }).toFormat('hh:mm')
export const fromTime = (time: string) => {
  const [hours, minutes] = time.split(':')
  // @ts-ignore hmmm. duration is expecting hours and minutes to be numbers
  // not strings
  return Duration.fromObject({ hours, minutes }).as('seconds')
}

export const toDate = (offset: number) => {
  // Take the offset seconds, turn them to millis and then attach them to a base
  // date (01/01/2017) to make excel happy with a datetime.
  const date = new Date(offset * 1000 + BASE_DATE_TIME)
  // Add the date's local timezone offset to the output into excel so that
  // excel interprets the time correctly (it has it's own offset logic that it
  // attempts to offset things with)
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
}

// Opposite logic from toDate above: take the date give by excel, and subtract
// the date component, as well as the timezone offset that excel attaches to get
// back to a seconds of week offset
export const fromDate = (date: Date) =>
  Math.round(
    (date.valueOf() - (BASE_DATE_TIME + date.getTimezoneOffset() * 60 * 1000)) /
      1000,
  )

export const toDuration = (secs: number) => secs / constants.SECONDS_PER_DAY
export const fromDuration = (duration: number) =>
  Math.round(duration * constants.SECONDS_PER_DAY)

export const deltatoExcel = (secs: Delta) =>
  secs.toSeconds() / constants.SECONDS_PER_DAY

export const cyclicTimeToExcel = (time: CyclicTime) => toDate(time.toSeconds())

export const toJSON = JSON.stringify
export const fromJSON = JSON.parse

export const TimeOfWeekSchema = {
  type: 'integer',
  multipleOf: 60,
  minimum: 0,
  maximum: 604800,
}

export class MissingFieldError extends Error {
  fieldDef: Field
  schema: Schema
  constructor(fieldDef: Field, schema: Schema) {
    // eslint-disable-next-line no-use-before-define
    super(`Missing field: ${Schema.getLoadedToField(fieldDef)}`) // TODO: i18n. Revisit
    this.fieldDef = fieldDef
    this.schema = schema
  }
}

export class SchemaValidationError extends Error {
  errorDef: object
  constructor(errorDef: object) {
    super(`Schema validation error ${JSON.stringify(errorDef)}`)
    this.errorDef = errorDef
  }
}

export class SchemaValidationErrors extends Error {
  errors: Error[]
  schema?: Schema
  constructor(errors: Error[], schema?: Schema) {
    super(`Schema validation errors ${errors.map(x => x.message).join(',')}`)
    this.errors = errors
    this.schema = schema
  }
}

type Field = {
  header: string
  loadTo?: string
  dumpFrom?: string
  load?: false | ((arg: any) => any)
  dump?: false | ((value: any, obj: any) => any)
  required?: boolean
  allowNull?: boolean
  default?: any
  validate?: { type: string; minimum?: number; maximum?: number }
}

export class Schema {
  postLoad: (arg: any) => any
  loadAttrs: { [key: string]: (arg: any) => any }

  constructor(
    public fields: Field[],
    { hooks = { postLoad: null as (arg: any) => any }, loadAttrs = {} } = {},
  ) {
    this.postLoad = hooks.postLoad
    this.loadAttrs = loadAttrs
  }

  get headers() {
    return [
      'document',
      ...this.fields.map(field => {
        const suffix = field.load !== false ? '' : '*'
        return `${field.header}${suffix}`
      }),
    ]
  }

  get fieldsToValidate() {
    return this.fields.filter(x => x.validate && x.load !== false)
  }

  get requiredFields() {
    return this.fields.filter(x => x.required === true)
  }

  static getLoadedToField(fieldDef: Field) {
    return fieldDef.loadTo || fieldDef.dumpFrom
  }

  dump(obj: {}, { id, name }: { id: number; name: string }) {
    return [
      `${name}#${id}`,
      ...this.fields.map(field => {
        try {
          const value = get(obj, field.dumpFrom)
          if (value === undefined) {
            throw Error()
          }
          return field.dump ? field.dump(value, obj) : value
        } catch (e) {
          return null
        }
      }),
    ]
  }

  load(row: any) {
    const result: any = {}
    for (const field of this.fields) {
      if (field.load !== false) {
        let value = row[field.header]
        const legitNull = field.allowNull && value === undefined

        if (legitNull) {
          value = null
        } else if (value === undefined && field.default !== undefined) {
          value = field.default
        }
        result[field.loadTo || field.dumpFrom] =
          field.load && value != null ? field.load(value) : value
      }
    }

    for (const [fieldName, fn] of Object.entries(this.loadAttrs)) {
      result[fieldName] = fn(row)
    }

    const postLoadResult = this.postLoad ? this.postLoad(result) : result
    const errors = this.validate(postLoadResult)
    if (errors.length > 0) {
      throw new SchemaValidationErrors(
        errors.map(x => new SchemaValidationError(x)),
      )
    }
    return postLoadResult
  }

  validateHeaders(headers: string[]) {
    const fields = headers
      .map(x => this.fields.find(y => y.header === x))
      .filter(x => x !== undefined) // Ignore extra headers
      .map(fieldDef => Schema.getLoadedToField(fieldDef))

    return this.validateRequiredFields(fields)
  }

  validateRequiredFields(fields: string[]) {
    const missing = this.requiredFields
      .map(x => Schema.getLoadedToField(x))
      .filter(x => !fields.includes(x))
      .map(x => this.fields.find(y => Schema.getLoadedToField(y) === x))

    return missing.map(x => new MissingFieldError(x, this))
  }

  static validateFieldType(type: string, value: any) {
    const createError = () => ({
      type: 'type',
      expected: type,
      found: getType(value),
      value,
    })

    switch (type) {
      case 'string': {
        if (typeof value !== 'string') {
          return createError()
        }
        break
      }
      case 'number':
      case 'integer': {
        if (typeof value !== 'number') {
          return createError()
        }
        if (type === 'integer') {
          if (Number.isSafeInteger(value) === false) {
            return createError()
          }
        }
        break
      }
      case 'boolean': {
        if (typeof value !== 'boolean') {
          return createError()
        }
        break
      }
      default: {
        throw new Error(`Unknown field type ${type}`)
      }
    }

    return null
  }

  static validateField(fieldDef: Field, data: any) {
    const fieldKey = Schema.getLoadedToField(fieldDef)
    const errors = []
    const value = data[fieldKey]
    if (value === undefined && fieldDef.required === true) {
      errors.push({
        type: 'required',
        expected: 'exist',
        found: 'undefined',
        value,
      })
    } else if (!(value === null && fieldDef.allowNull === true)) {
      if (fieldDef.validate.type && value !== undefined) {
        const typeCheckError = Schema.validateFieldType(
          fieldDef.validate.type,
          data[fieldKey],
        )
        if (typeCheckError !== null) {
          errors.push(typeCheckError)
        }
      }
    }

    return errors.map(
      x =>
        new SchemaValidationError({
          ...x,
          fieldDefinition: fieldDef,
          data,
        }),
    )
  }

  validate(data: any): (MissingFieldError | SchemaValidationError)[] {
    const requiredFieldErrors = this.validateRequiredFields(Object.keys(data))
    if (requiredFieldErrors.length > 0) {
      return requiredFieldErrors
    }

    return this.fieldsToValidate.flatMap(field =>
      Schema.validateField(field, data),
    )
  }
}
