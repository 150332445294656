import { isNotNullish } from 'src/core/types'
import { LogMessage } from 'src/service-design/shared/models/log-message'

export abstract class InstanceRepair extends LogMessage {
  // Don't stress about collectionName not being typed to the document collections,
  // we hope this is a temporary measure.
  // We can't type this because it's a name supplied to a generic CRUD delete, but
  // this repair action should be taken by something much closer to the model.
  // Ideally all the generic CRUD actions should go away.
  constructor(
    public affectedObj: any,
    // FIXME: Bulk ignored when upgrading react-scripts
    // eslint-disable-next-line default-param-last
    context: any = affectedObj,
    derived?: any,
  ) {
    super(context, derived)
  }

  get collectionName(): string {
    return (this.constructor as any).collectionName
  }

  static check(entity: any): boolean {
    return false
  }
}

type InstanceRepairConstructor<T> = {
  collectionName: string
  new (arg: T): InstanceRepair
  check(arg: T): boolean
}
type InstanceRepairFactory<T> = (entities: T[]) => InstanceRepair[]
type RepairSelector = (state: any) => InstanceRepair[]

export class RepairRegistry {
  selectors: RepairSelector[]

  constructor() {
    this.selectors = []
  }

  register(selector: (state: any) => InstanceRepair[]) {
    this.selectors.push(selector)
  }

  build(state: any): InstanceRepair[] {
    return this.selectors.flatMap(selector => selector(state))
  }
}

class InstanceRepairFailed extends InstanceRepair {
  public get collectionName(): string {
    return this._collectionName
  }

  public set collectionName(value: string) {
    this._collectionName = value
  }

  constructor(
    public affectedObj: any,
    private _collectionName: string,
    context: any = affectedObj,
  ) {
    super(affectedObj, context, { collectionName: _collectionName })
  }

  static type = 'service-design::Document repair function failed'
  static message =
    'service-design::A {{collectionName}} repair function failed.'
}

export const getRepairFactory =
  <T>(
    repairClasses: InstanceRepairConstructor<T>[],
  ): InstanceRepairFactory<T> =>
  (entities: T[]) =>
    repairClasses.flatMap(
      (RepairClass: InstanceRepairConstructor<T>): InstanceRepair[] =>
        entities
          .map(e => {
            try {
              return RepairClass.check(e) ? new RepairClass(e) : null
            } catch {
              return new InstanceRepairFailed(e, RepairClass.collectionName)
            }
          })
          .filter(isNotNullish),
    )
