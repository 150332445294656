import { createAction, ActionType } from 'typesafe-actions'

import { Overwrite } from 'utility-types'
import { v4 as uuid } from 'uuid'

import { batchActions } from 'src/core/batchMiddleware'

import { IBackendDocumentMeta } from 'src/core/types/document'
import * as constants from 'src/service-design/shared/constants'
import { documentsReceiveByQuery } from 'src/service-design/shared/document/actions/documents-by-query'
import { BaseDocumentData } from 'src/service-design/shared/document/types'

import { notificationPost } from 'src/service-design/shared/ui/Notifications/actions'

export {
  legacySaveImportedDocument,
  importDocumentFromFile,
  parseWorkbook,
  ImportError,
  FixableError,
} from './import'
export { setupSave } from './save'
export {
  acknowledgeDocumentErrors,
  postDocumentLoadDisplayModalError,
} from './post-document-load'
export { revisionsReceive } from './revisionsReceive'

export const documentErrorsUpdate = createAction(
  constants.DOCUMENT_ERRORS_UPDATE,
)<{ id: string; message: string }[]>()
export type DocumentErrorsUpdateActionType = ActionType<
  typeof documentErrorsUpdate
>

export interface InstanceAdd<D extends BaseDocumentData> {
  <U extends keyof D>(
    collection: U,
    instance: Overwrite<D[U][0], { id?: string }>,
  ): {
    type: typeof constants.INSTANCE_ADD
    payload: { collection: U; instance: D[U][0] }
  }
}

export const instanceAdd: unknown = (
  collection: any,
  { id, ...instance }: any,
) => ({
  type: constants.INSTANCE_ADD,
  payload: {
    collection,
    instance: { id: id || uuid(), ...instance },
  },
})

export interface InstanceEdit<D extends BaseDocumentData> {
  <U extends keyof D>(
    collection: U,
    instance: D[U][0],
  ): {
    type: typeof constants.INSTANCE_EDIT
    payload: { collection: U; instance: D[U][0] }
  }
}

export const instanceEdit: unknown = (collection: any, instance: any) => ({
  type: constants.INSTANCE_EDIT,
  payload: {
    collection,
    instance,
  },
})

export interface SingletonEdit<S extends Record<string, {}>> {
  <U extends keyof S>(
    singleton: U,
    instance: S[U],
  ): {
    type: typeof constants.SINGLETON_EDIT
    payload: { singleton: U; instance: S[U] }
  }
}

export const singletonEdit: unknown = (singleton: any, instance: any) => ({
  type: constants.SINGLETON_EDIT,
  payload: {
    singleton,
    instance,
  },
})

export interface InstanceSubmit<D extends BaseDocumentData> {
  <U extends keyof D>(
    collection: U,
    instance: Overwrite<D[U][0], { id?: string }>,
  ): {
    type: typeof constants.INSTANCE_ADD | typeof constants.INSTANCE_EDIT
    payload: {
      collection: U
      instance: D[U][0]
    }
  }
}

/**
 * TODO: the internal implementation here isn't safely typed, it is just cast
 * to make the compiler happy. Probably need to come back to this and fix it at
 * a later stage
 **/
export const instanceSubmit: unknown = (collection: any, instance: any) => {
  const submit = (instance.id ? instanceEdit : instanceAdd) as (
    collection: any,
    instance: any,
  ) => any
  return submit(collection, instance)
}

export type UnsafeInstanceDelete = (collection: any, filterObj: any) => any

export interface InstanceDelete<D extends BaseDocumentData> {
  <U extends keyof D>(
    collection: U,
    filterObj: Partial<D[U][0]> | string,
  ): {
    type: typeof constants.INSTANCE_DELETE
    payload: {
      collection: U
      filter: Partial<D[U][0]>
    }
  }
}

export const instanceDelete: unknown = (collection: any, filterObj: any) => {
  const filter = filterObj instanceof Object ? filterObj : { id: filterObj }
  return {
    type: constants.INSTANCE_DELETE,
    payload: {
      collection,
      filter,
    },
  }
}

export const revisionSaveStarted = createAction('REVISION_SAVE_STARTED')()
export const revisionSaving = createAction('REVISION_SAVING')()
export const revisionSaveFailed = createAction('REVISION_SAVE_FAILED')()
export const revisionSaveStopped = createAction('REVISION_SAVE_STOPPED')()
export const documentUpdated =
  createAction('DOCUMENT_UPDATED')<IBackendDocumentMeta>()
export const revisionSaved = createAction('REVISION_SAVED')<{}>()

export const saveActions = {
  revisionSaveStarted,
  revisionSaving,
  revisionSaveFailed,
  revisionSaveStopped,
  documentUpdated,
  revisionSaved,
} as const
export type SaveActionType = ActionType<typeof saveActions>

export const retryFailed = (message: string) =>
  batchActions([
    revisionSaveStopped(),
    notificationPost(
      'There was an error saving the document.',
      message,
      'error',
    ),
  ])

type DocumentPayload = {
  id: number
  type: string
}

export const documentReceive =
  createAction('DOCUMENT_RECEIVE')<IBackendDocumentMeta>()

export const documentRemove = createAction('DOCUMENT_REMOVE')<DocumentPayload>()

export const documentRename = createAction(
  'DOCUMENT_RENAME',
  (item: DocumentPayload, name: string) => ({ ...item, name }),
)()

export const documentProperties = createAction(
  'DOCUMENT_PROPERTIES',
  (item: DocumentPayload, properties: { description: string }) => ({
    ...item,
    properties,
  }),
)()

export const documentUnarchive =
  createAction('DOCUMENT_UNARCHIVE')<DocumentPayload>()

export const documentActions = {
  documentsReceiveByQuery,
  documentReceive,
  documentRemove,
  documentRename,
  documentProperties,
  documentUnarchive,
} as const

export type DocumentActionType = ActionType<typeof documentActions>
