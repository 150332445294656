import { readAsArrayBuffer } from 'promise-file-reader'

import { v4 as uuid } from 'uuid'
import * as xlsx from 'xlsx'

import i18n from 'src/i18n'
import api from 'src/service-design/shared/api'
import { revisionsReceive } from 'src/service-design/shared/document/actions'
import { documentPost } from 'src/service-design/shared/document/actions/api'
import { postDocumentLoadCheckOnly } from 'src/service-design/shared/document/actions/post-document-load'
import { getSaveSelector } from 'src/service-design/shared/document/selectors/save'
import { DocumentSpec } from 'src/service-design/shared/document/types'
import {
  Schema,
  SchemaValidationErrors,
} from 'src/service-design/shared/exporter/schema'
import {
  WorkbookDefinition,
  isCollectionDefinition,
  isSingletonDefinition,
} from 'src/service-design/shared/exporter/worksheet'

export const MAX_NUM_SPREADSHEET_COLUMNS = 50

export class ImportError extends Error {
  constructor(
    public errors: (
      | {
          id: string
          type: string
          message: string
        }
      | string
    )[],
  ) {
    super('The file could not be imported') // TODO: i18n. Revisit
  }
}

interface ErrorSource {
  sourceMissingName: string
  sourceMissingId: string
  collections: { [collection: string]: string[] }
  message: string
}

interface ErrorMessage {
  [errorSource: string]: ErrorSource
}

export class FixableError extends Error {
  constructor(public errors: ErrorMessage) {
    super()
  }
}

export class TooManySheetColumnsWarning {
  constructor(public sheet: string) {}

  get stack() {
    return i18n.t(
      'common::In the file uploaded, the sheet {{ sheet }} has a large number of columns. All columns after column {{ max_num }} were ignored. Note that these may be blank and they should be deleted.',
      {
        sheets: this.sheet,
        max_num: MAX_NUM_SPREADSHEET_COLUMNS,
      },
    )
  }
}

const headersFromXlsx = (xlsxSheet: xlsx.WorkSheet) => {
  const range = xlsx.utils.decode_range(xlsxSheet['!ref'])
  const endColIdx = range.e.c + 1

  return [...Array(endColIdx).keys()].map(colIdx => {
    const cell = xlsxSheet[xlsx.utils.encode_cell({ r: 0, c: colIdx })]
    return cell ? cell.v : ''
  })
}

export const checkSpreadsheetFields = (
  xlsxSheet: xlsx.WorkSheet,
  schema: Schema,
  sheetName: string,
) => {
  const cols = headersFromXlsx(xlsxSheet)
  const errors = schema.validateHeaders(cols)
  if (errors.length > 0) {
    throw new ImportError(
      errors.map(error => ({
        id: `${sheetName}-${error.fieldDef.header}`,
        type: 'Missing column',
        message: `Column ${error.fieldDef.header} is missing from the sheet ${sheetName}`, // TODO: i18n
      })),
    )
  }
}

export type ParseWorkbookResult =
  | { type: 'SUCCESS'; data: any; badSheets: string[] }
  | {
      type: 'ERROR'
      summary: string
      errors: { id: string; message: string }[]
      badSheets: string[]
    }

// TODO: modify parseWorkbook so it doesn't throw expections and the
// this wrapper won't be required.
export const parseWorkbookWrapper = <
  DocumentKind extends string,
  ParentDocumentKind extends string = never,
>(
  workbook: xlsx.WorkBook,
  workbookDefinition: WorkbookDefinition<DocumentKind, ParentDocumentKind>,
): ParseWorkbookResult => {
  let data: any
  let warnings: TooManySheetColumnsWarning[]
  try {
    ;({ data, warnings } = parseWorkbook(workbook, workbookDefinition))
  } catch (e) {
    if (e instanceof ImportError) {
      return {
        type: 'ERROR',
        summary: 'There was a problem reading the excel file',
        // NOTE: it would be really useful if we could pass back badSheet
        // information in the error case too!
        errors: e.errors.map(x => ({
          id: uuid(),
          message: typeof x === 'string' ? x : x.message,
        })),
        badSheets: [],
      }
    }
    if (e instanceof SchemaValidationErrors) {
      return {
        type: 'ERROR',
        summary: 'There was a problem reading the excel file',
        // NOTE: it would be really useful if we could pass back badSheet
        // information in the error case too!
        errors: e.errors.map(x => ({
          id: uuid(),
          message: typeof x === 'string' ? x : x.message,
        })),
        badSheets: [],
      }
    }
    // TODO: Are all these errors really unexpected?
    console.error(e)
    return {
      type: 'ERROR',
      summary: 'There was a problem reading the excel file',
      errors: [{ id: uuid(), message: 'An unexcepted error occured.' }],
      badSheets: [],
    }
  }
  return {
    type: 'SUCCESS',
    data,
    badSheets: warnings.map(x => x.sheet),
  }
}

export const parseWorkbook = <
  DocumentKind extends string,
  ParentDocumentKind extends string = never,
>(
  workbook: xlsx.WorkBook,
  workSheets: WorkbookDefinition<DocumentKind, ParentDocumentKind>,
) =>
  workSheets.sheets.reduce<{
    data: {
      [key: string]: {}[] | {}
      singletons: { [key: string]: {} }
    }
    warnings: TooManySheetColumnsWarning[]
  }>(
    (acc, worksheetDef) => {
      const { sheet: sheetName, schema, exclude } = worksheetDef
      const sheet = workbook.Sheets[sheetName]

      if (!exclude && !sheet) {
        throw new ImportError([`Missing '${sheetName}' sheet`])
      }

      if (
        (isCollectionDefinition(worksheetDef) ||
          isSingletonDefinition(worksheetDef)) &&
        sheet
      ) {
        const sheetRangeRef = sheet['!ref']

        // If a sheet is completely empty, it won't even have a `!ref` property
        // and we should bail out with a useful error
        if (!sheetRangeRef) {
          throw new ImportError([
            i18n.t(
              'common::The sheet "{{ sheet }}" is empty. It requires the columns {{ columns }}',
              {
                sheet: sheetName,
                columns: schema.fields.map(f => `"${f.header}"`).join(', '),
              },
            ),
          ])
        }

        const sheetRange = xlsx.utils.decode_range(sheetRangeRef)

        if (sheetRange.e.c > MAX_NUM_SPREADSHEET_COLUMNS) {
          acc.warnings.push(new TooManySheetColumnsWarning(sheetName))
        }

        const limitedSheetRange = {
          ...sheetRange,
          e: {
            ...sheetRange.e,
            c: Math.min(sheetRange.e.c, MAX_NUM_SPREADSHEET_COLUMNS),
          },
        }

        const rows = xlsx.utils.sheet_to_json(sheet, {
          raw: true,
          range: limitedSheetRange,
        })

        if (rows.length > 0) {
          checkSpreadsheetFields(sheet, schema, sheetName)
        }
        const processed = rows.map(row => schema.load(row))

        if (isCollectionDefinition(worksheetDef)) {
          acc.data[worksheetDef.collection] = processed
        } else {
          // eslint-disable-next-line prefer-destructuring
          acc.data.singletons[worksheetDef.singleton] = processed[0]
        }
        return acc
      }

      if (isCollectionDefinition(worksheetDef)) {
        acc.data[worksheetDef.collection] = []
      } else if (isSingletonDefinition(worksheetDef)) {
        acc.data.singletons[worksheetDef.singleton] = {}
      }

      return acc
    },
    { data: { singletons: {} }, warnings: [] },
  )

const validateDocuments = async ({
  documentSpec,
  dispatch,
}: {
  documentSpec: DocumentSpec<string>
  dispatch: any
}) => {
  const { unfixable, fixed } = await dispatch(
    postDocumentLoadCheckOnly({ documentSpec }),
  )

  if (unfixable.length > 0) {
    throw new ImportError(unfixable)
  }

  if (Object.keys(fixed).length > 0) {
    throw new FixableError(fixed)
  }
}

export const importDocumentFromFile =
  <DocumentKind extends string, ParentDocumentKind extends string = null>({
    file,
    workbookDefinition,
  }: {
    file: File
    workbookDefinition: WorkbookDefinition<DocumentKind, ParentDocumentKind>
  }) =>
  async (dispatch: any) => {
    const { documentSpec } = workbookDefinition
    const { kind: type } = documentSpec
    try {
      const array = await readAsArrayBuffer(file)
      const workbook = xlsx.read(array, { type: 'array', cellDates: true })
      const { data: workbookData, warnings } = parseWorkbook(
        workbook,
        workbookDefinition,
      )

      // validate the shape of the imported workbook
      // TODO the *schema* validation that is done in this call is *already performed* by parseWorkbook
      const bookErrors = workbookDefinition.validate(workbookData)
      if (bookErrors.length) {
        throw new ImportError(bookErrors)
      }

      // TODO vvv once validation doesn't require unnecessary collections from the blank document, remove this vvv
      const data = { ...documentSpec.blank, ...workbookData }
      // TODO BOSS-3757 dispatch reception of traingraph too? how does it get integrated into excel?
      // @ts-ignore
      await dispatch(revisionsReceive([{ data, meta: { type } }]))

      // validate / repair the generated document
      await validateDocuments({ documentSpec, dispatch })
      return { warnings }
    } catch (e) {
      if (e instanceof ImportError || e instanceof FixableError) {
        throw e
      } else {
        console.error(e)
        throw new ImportError([
          {
            id: null,
            type: 'File read error',
            message: (e as { message?: string })?.message,
          },
        ])
      }
    }
  }

// TODO: this function and the next one are REAL SIMILAR, get rid of this and use the other one
export const legacySaveImportedDocument =
  ({
    fileName,
    type,
    parentId,
    currentVersion,
  }: {
    fileName: string
    type: string
    parentId: number
    currentVersion: string
  }) =>
  async (dispatch: any, getState: any) => {
    let resp
    try {
      resp = await documentPost(
        fileName,
        type,
        getSaveSelector()(getState()).data,
        parentId,
        currentVersion,
      )
    } catch (e) {
      throw new ImportError([
        {
          id: null,
          type: api.isFetchError(e) ? e.data?.code : '-1',
          message: 'There was an unexpected error while saving the document',
        },
      ])
    }

    const { id } = resp.data
    return id
  }

type SaveResult =
  | { type: 'SUCCESS'; id: number }
  | { type: 'SAVE_ERROR'; summary: string; message: string }

export async function saveImportedDocument<T>(
  fileName: string,
  data: T,
  type: string,
  currentVersion: string,
): Promise<SaveResult> {
  let resp
  try {
    resp = await documentPost(fileName, type, data, null, currentVersion)
  } catch (e) {
    if (api.isFetchError(e)) {
      return {
        type: 'SAVE_ERROR',
        summary: 'Error saving the document',
        message: `Network error: ${e.statusText} [${e.status}]. ${e?.data?.message}`,
      }
    }
    console.error(e)
    return {
      type: 'SAVE_ERROR',
      summary: 'Error saving the document',
      message: 'There was an unexpected error while saving the document.',
    }
  }
  return { type: 'SUCCESS', id: resp.data.id }
}
